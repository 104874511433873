import { useState } from "react";
import { cn } from "utils/utils";
import { Label } from "./Label";

export const SwitchBar = ({
  options,
  bookmark = false,
  onSwitch,
  defaultOption = options[0].value,
  className,
  itemClassName,
  name,
}: {
  options: { name: string; value: string }[];
  name?: string;
  bookmark?: boolean;
  onSwitch?: (index: string) => void;
  defaultOption?: string;
  className?: string;
  itemClassName?: string;
}) => {
  const [selectedValue, setSelectedValue] = useState<string>(defaultOption);
  const onValueChange = (index: string) => {
    setSelectedValue(index);
    if (onSwitch) {
      onSwitch(index);
    }
  };
  return (
    <div
      className={cn(
        "flex w-fit items-center max-w-[90vw] gap-1 overflow-x-auto rounded-lg border border-solid border-gray-300 bg-white p-1",
        className,
      )}
    >
      {options.map((option) => (
        <SubSwitchBar
          onValueChange={() => onValueChange(option.value)}
          haveBookmark={bookmark}
          key={option.value}
          active={selectedValue === option.value}
          text={option.name}
          className={itemClassName}
        />
      ))}
      <input type="hidden" name={name} value={selectedValue} />
    </div>
  );
};
const SubSwitchBar = ({
  active,
  text,
  haveBookmark,
  onValueChange,
  className,
}: {
  active: boolean;
  text: string;
  haveBookmark: boolean;
  onValueChange: () => void;
  className?: string;
}) => {
  const bookmark = (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.0005 13L12.0005 7M9.00049 10L15.0005 10M19.0005 21L19.0005 7.8C19.0005 6.11984 19.0005 5.27976 18.6735 4.63803C18.3859 4.07354 17.9269 3.6146 17.3625 3.32698C16.7207 3 15.8806 3 14.2005 3L9.80049 3C8.12033 3 7.28025 3 6.63852 3.32698C6.07403 3.6146 5.61509 4.07354 5.32747 4.63803C5.00049 5.27976 5.00049 6.11984 5.00049 7.8L5.00049 21L12.0005 17L19.0005 21Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  return (
    <button
      type="button"
      className={cn(
        `inline-flex h-10 shrink-0 cursor-pointer items-center justify-center gap-2.5 rounded-lg px-5 py-2 text-gray-800 focus:outline-none`,
        {
          "text-gray-700 hover:bg-gray-100 hover:text-gray-700 focus:bg-gray-200":
            !active,
          "bg-green-600 text-white": active,
        },
        className,
      )}
      onClick={onValueChange}
    >
      {haveBookmark ? bookmark : null}
      <Label as="p" size={"M"} className={"text-inherit"}>
        {text}
      </Label>
    </button>
  );
};
