import { loadStripe } from "@stripe/stripe-js";
import { useEnv } from "app/root";
import { PaymentIntentStatus } from "app/shared";
import { CustomerTypes } from "app/shared/schemas/user-roles.enum";
import { bookingStatuses } from "app/shared/schemas/utils.schema";
import { ArrowTriangle } from "components/Icons/ArrowTriangle";
import { Refresh } from "components/Icons/Refresh";
import { Time } from "components/Icons/Time";
import { Truck } from "components/Icons/Truck";
import { OptimizedImage } from "components/OptimizedImage";
import { Headline } from "components/UI/Headline";
import { Label } from "components/UI/Label";
import { Paragraph } from "components/UI/Paragraph";
import { StripePaymentWrapper } from "components/UI/PaymentForm";
import { SwitchBar } from "components/UI/SwitchBar";
import { ClintButton } from "components/shadcn-ui/button";
import { useOptionalUser } from "hooks/useUser";
import { useQueryStates } from "nuqs";
import {
  Form,
  Link,
  type MetaFunction,
  data,
  redirect,
  useLoaderData,
} from "react-router";
import { generateCanonicalUrl } from "routes/redirects.server";
import { getBooking } from "server/api/customer/services/customer-bookings.server";
import { chargeZohoInvoice } from "server/api/zoho/zoho.server";
import { getUser } from "server/session.server";
import "styles/nprogress.css";
import { formatPriceWithCurrency } from "utils/format-price";
import { generateMetatags } from "utils/generateMetatags.ts";
import { createToastHeadersWithFeedback } from "utils/toast.server";
import type { Route } from "./+types/payer.$bookingId";
import equipmentImage from "./equipment.png";
import {
  BookingPriceDetailItem,
  type MarketplaceSearchParamsQueryStatesProps,
  marketplaceSearchParams,
} from "./rechercher";
const dynamicLinks = ({
  data,
}: {
  data: { href: string };
}): ReturnType<MetaFunction> => [
  {
    rel: "canonical",
    href: data.href,
  },
];

export const handle = {
  // 👇This is a function that will be called client side with a 'href' argument.
  dynamicLinks,
};

export const loader = async ({ request, params }: Route.LoaderArgs) => {
  const bookingId = params.bookingId;
  const user = await getUser({ request });
  const booking = await getBooking({ bookingId: Number(bookingId) });

  if (
    user &&
    booking.customer.user.id === user.id &&
    booking.status.gcId !== bookingStatuses.STRIPE_PENDING_PAYMENT.id
  ) {
    throw redirect(`/dashboard/${bookingId}`, {
      headers: await createToastHeadersWithFeedback({
        feedback: {
          error: false,
          message: ["Cette réservation a déjà été payée."],
        },
      }),
    });
  }
  return data({
    booking,
    href: generateCanonicalUrl({ request }),
  });
};

export const meta: MetaFunction<typeof loader> = ({ location }) => {
  return generateMetatags({
    noIndex: true,
    pageUrl: location.pathname,
  });
};

export const action = async ({ params: { bookingId } }: Route.ActionArgs) => {
  const booking = await getBooking({ bookingId: Number(bookingId) });

  if (booking.stripePaymentIntentId) {
    return data(
      {
        booking,
      },
      {
        headers: await createToastHeadersWithFeedback({
          feedback: {
            error: false,
            message: [
              "Cette réservation possède déjà une intention de paiement.",
            ],
          },
        }),
      },
    );
  }
  if (!booking.zohoInvoiceId) {
    return data(`/dashboard/${bookingId}`, {
      headers: await createToastHeadersWithFeedback({
        feedback: {
          error: true,
          message: ["Cette réservation n'est pas attachée à une facture."],
        },
      }),
    });
  }

  const { error, message } = await chargeZohoInvoice({
    paymentMethodId: booking.stripePaymentMethodId,
    invoiceId: booking.zohoInvoiceId,
    stripeCustomerId: booking.customer.stripeCustomerId,
    bookingId: Number(bookingId),
    // status: booking.downgrading
    //   ? PaymentIntentStatus.BOOKING_DOWNGRADING_PAYMENT
    //   : PaymentIntentStatus.INVOICE_FINAL_PAYMENT,
    status: PaymentIntentStatus.INVOICE_DOWN_PAYMENT,
    offSession: false,
    finalPayment: false,
    isProfessional: booking.customer.type === CustomerTypes.PROFESSIONAL,
  });
  return data(
    {
      booking,
    },
    {
      headers: await createToastHeadersWithFeedback({
        feedback: {
          error,
          message,
        },
      }),
    },
  );
};

export default function PaymentSection() {
  const { booking } = useLoaderData<typeof loader>();

  if (!booking) return null;

  const {
    provider: { doubleRotationBennePrice },
    bookingPrices: { priceTTC, rentPriceTTC, billedHours },
  } = booking;

  const { STRIPE_PUBLISHABLE_KEY } = useEnv() || {};
  const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY || "");
  const [selectedFilters, setSelectedFilters] = useQueryStates(
    marketplaceSearchParams,
  );
  const user = useOptionalUser();

  return (
    <div className="flex flex-1">
      <div className="relative flex h-full w-full flex-col gap-4 bg-gray-100 px-0 lg:min-h-screen lg:flex-row lg:px-10">
        <div className="flex w-full max-w-full flex-1 grow flex-col items-start gap-10 lg:basis-[750px]">
          <div className="flex w-full flex-col gap-4">
            <div className="flex flex-col gap-6 bg-white p-5 lg:rounded-[8px] lg:p-6">
              <button type="submit" className="hidden" />
              <div className="flex flex-col gap-4">
                <Headline size="h5" variant={"secondary"}>
                  À régler aujourd'hui
                </Headline>
                <Paragraph size="md" variant={"secondary"}>
                  {formatPriceWithCurrency(priceTTC)} TTC
                  {/* Merci de bien fournir les dates réelles de votre réservation. */}
                </Paragraph>
              </div>

              {booking.paymentIntentStatus === "succeeded" ? (
                <Headline size="h6" variant={"secondary"}>
                  Le paiement de cette réservation a été effectué.
                  <br />{" "}
                  {booking.customer.id === user?.customerId ? (
                    <Link
                      className="text-lg underline"
                      to={`/dashboard/${booking.id}`}
                    >
                      Accédez au détail de la réservation pour en savoir plus.
                    </Link>
                  ) : (
                    <Link
                      className="text-lg underline"
                      to={`/login?redirectTo=/dashboard/${booking.id}`}
                    >
                      Veuillez vous connecter pour pouvoir accéder au détail de
                      la réservation.
                    </Link>
                  )}
                </Headline>
              ) : booking.status.gcId !==
                bookingStatuses.STRIPE_PENDING_PAYMENT.id ? (
                <Headline size="h6" variant={"secondary"}>
                  Le paiement de cette réservation a été effectué, ou elle a été
                  annulée. Veuillez contacter l'assistance pour plus
                  d'informations.
                </Headline>
              ) : booking.clientSecret && STRIPE_PUBLISHABLE_KEY ? (
                <StripePaymentWrapper
                  clientSecret={booking.clientSecret}
                  bookingId={booking.id}
                  onError={(e) => {
                    console.error(e);
                  }}
                  stripePromise={stripePromise}
                />
              ) : booking.paymentIntentStatus === "canceled" ? (
                <Headline size="h6" variant={"secondary"}>
                  Le paiement de cette réservation a été annulé.
                </Headline>
              ) : booking.paymentIntentStatus !== null ? (
                <Headline size="h6" variant={"secondary"}>
                  Cette réservation n'a pas encore été payée, mais elle possède
                  déjà une intention de paiement. Veuillez contacter
                  l'assistance.
                </Headline>
              ) : (
                <div className="flex flex-col gap-4">
                  <Headline size="h6" variant={"secondary"}>
                    Nous ne retrouvons pas les informations de paiement de cette
                    prestation. Cliquez sur le bouton ci-dessous pour régler la
                    facture.
                  </Headline>
                  <Form method="POST">
                    <ClintButton as="Button" type="submit" variant="primary">
                      Régler la facture
                    </ClintButton>
                  </Form>
                </div>
              )}
            </div>
          </div>
        </div>

        <BookingPriceRecap
          billedHours={billedHours}
          transportRotationPriceHT={
            booking.bookingPrices.transportRotationPriceHT
          }
          transportRotationPriceTTC={
            booking.bookingPrices.transportRotationPriceTTC
          }
          doubleRotationBennePrice={doubleRotationBennePrice}
          equipment={{
            imageUrl: booking.equipment.imageUrl,
            type: {
              name: booking.equipment.type.name,
            },
            volume: booking.equipment.volume,
          }}
          priceHT={booking.bookingPrices.priceHT}
          priceTTC={booking.bookingPrices.priceTTC}
          tvaFee={booking.bookingPrices.vatFee}
          equipmentWeight={booking.equipmentWeight}
          pricePerHourHT={booking.bookingPrices.pricePerHourHT}
          pricePerHourTTC={booking.bookingPrices.pricePerHourTTC}
          rentDays={booking.bookingPrices.rentDays}
          rentPricePerDayHT={booking.bookingPrices.rentPricePerDayHT}
          rentPricePerDayTTC={booking.bookingPrices.rentPricePerDayTTC}
          rentPriceHT={booking.bookingPrices.rentPriceHT}
          rentPriceTTC={rentPriceTTC}
          selectedFilters={{
            address: booking.customerAddress,
            immediatePickup: booking.immediatePickup ? "1" : "0",
            plans: booking.isPlan ? "1" : "0",
            isProfessional:
              booking.customer.type === CustomerTypes.PROFESSIONAL ? "1" : "0",
            isRecurring: selectedFilters.isRecurring,
          }}
          setSelectedFilters={setSelectedFilters}
          transportDeliveryPriceHT={
            booking.bookingPrices.transportDeliveryPriceHT
          }
          transportDeliveryPriceTTC={
            booking.bookingPrices.transportDeliveryPriceTTC
          }
          transportPickupPriceHT={booking.bookingPrices.transportPickupPriceHT}
          transportPickupPriceTTC={
            booking.bookingPrices.transportPickupPriceTTC
          }
          treatmentPricePerTonHT={booking.bookingPrices.treatmentPricePerTonHT}
          treatmentPricePerTonTTC={
            booking.bookingPrices.treatmentPricePerTonTTC
          }
          treatmentPriceHT={booking.bookingPrices.treatmentPriceHT}
          treatmentPriceTTC={booking.bookingPrices.treatmentPriceTTC}
          waste={{
            gcId: booking.wasteType.gcId,
            name: booking.wasteType.name,
          }}
          providerName={booking.provider.businessName}
        />
      </div>
    </div>
  );
}

export const BookingPriceRecap = ({
  billedHours,
  doubleRotationBennePrice,
  equipment,
  equipmentWeight,
  pricePerHourHT,
  pricePerHourTTC,
  rentDays,
  rentPricePerDayHT,
  rentPricePerDayTTC,
  rentPriceHT,
  rentPriceTTC,
  treatmentPricePerTonHT,
  treatmentPricePerTonTTC,
  treatmentPriceHT,
  treatmentPriceTTC,
  transportDeliveryPriceHT,
  transportDeliveryPriceTTC,
  transportPickupPriceHT,
  transportPickupPriceTTC,
  transportRotationPriceHT,
  transportRotationPriceTTC,
  waste,
  priceHT,
  priceTTC,
  tvaFee,
  selectedFilters,
  providerName,
  setSelectedFilters,
}: {
  transportDeliveryPriceHT: number;
  transportDeliveryPriceTTC: number;
  transportPickupPriceHT: number;
  transportPickupPriceTTC: number;
  transportRotationPriceHT: number;
  transportRotationPriceTTC: number;
  treatmentPricePerTonHT: number;
  treatmentPricePerTonTTC: number;
  treatmentPriceHT: number;
  treatmentPriceTTC: number;
  rentDays: number;
  billedHours: number;
  rentPricePerDayHT: number;
  rentPricePerDayTTC: number;
  rentPriceHT: number;
  rentPriceTTC: number;
  pricePerHourHT: number;
  pricePerHourTTC: number;
  priceHT: number;
  priceTTC: number;
  tvaFee: number;
  equipmentWeight: number;
  providerName: string;
  equipment: {
    imageUrl: string;
    type: {
      name: string;
    };
    volume: number;
  };
  doubleRotationBennePrice: boolean;
  waste: {
    name: string;
    gcId: number;
  };
  selectedFilters: {
    address: string;
    immediatePickup: "0" | "1";
    plans: "0" | "1";
    isProfessional: "0" | "1";
    isRecurring: "0" | "1";
  };
  setSelectedFilters: MarketplaceSearchParamsQueryStatesProps["setSelectedFilters"];
}) => {
  const user = useOptionalUser();
  const isPro = selectedFilters.isProfessional === "1";

  let displayedTransportPrice = 0;
  if (isPro) {
    displayedTransportPrice = doubleRotationBennePrice
      ? transportRotationPriceHT * 2
      : transportRotationPriceHT;
  } else {
    displayedTransportPrice = doubleRotationBennePrice
      ? transportRotationPriceTTC * 2
      : transportRotationPriceTTC;
  }

  const displayedTransportDeliveryPrice = isPro
    ? transportDeliveryPriceHT
    : transportDeliveryPriceTTC;

  const displayedTransportPickupPrice = isPro
    ? transportPickupPriceHT
    : transportPickupPriceTTC;

  const displayedTreatmentPricePerTon = isPro
    ? treatmentPricePerTonHT
    : treatmentPricePerTonTTC;
  const displayedTreatmentPrice = isPro ? treatmentPriceHT : treatmentPriceTTC;
  const displayedRentPrice = isPro ? rentPriceHT : rentPriceTTC;
  const displayedRentPricePerDay = isPro
    ? rentPricePerDayHT
    : rentPricePerDayTTC;

  const displayedPricePerHour = isPro ? pricePerHourHT : pricePerHourTTC;

  return (
    <div className="flex h-fit flex-1 flex-col gap-6 bg-white p-5 lg:basis-[560px] lg:rounded-[8px] lg:border lg:border-gray-300 lg:p-6">
      {user?.authOptions.isAdmin && providerName ? (
        <Paragraph size="lg" className="py-1 font-bold text-rouge">
          {providerName}
        </Paragraph>
      ) : null}

      {user?.authOptions.isAdmin ? (
        <>
          <SwitchBar
            options={[
              { name: "Prestation récurrente", value: "1" },
              { name: "Prestation ponctuelle", value: "0" },
            ]}
            defaultOption={selectedFilters.isRecurring}
            onSwitch={(newValue) => {
              setSelectedFilters?.({
                isRecurring: newValue === "1" ? "1" : "0",
              });
            }}
          />
          <SwitchBar
            options={[
              { name: "Enlèvement immédiat", value: "1" },
              { name: "Dépose et retrait", value: "0" },
            ]}
            defaultOption={selectedFilters.immediatePickup}
            onSwitch={(newValue) => {
              setSelectedFilters?.({
                immediatePickup: newValue === "1" ? "1" : "0",
              });
            }}
          />
        </>
      ) : null}
      <div className="flex w-fit flex-col items-start gap-10 lg:flex-row lg:items-center">
        <OptimizedImage
          source={equipment.imageUrl || equipmentImage}
          maxWidth={256}
          alt={equipment.type.name}
          className="max-w-[128px]"
          containerClassName="mx-0"
          imageProps={{
            minHeight: "100%",
            objectFit: "contain",
          }}
        />
        <div className="flex flex-col gap-2">
          <Label size="M" variant="secondary">
            {`${equipment.type.name} - ${equipment.volume}m3`}
          </Label>
          <Label size="S" variant={"secondaryDarker"} className="text-gray-600">
            {waste.name}
          </Label>
          <Paragraph size="sm" variant="secondaryDarker">
            {selectedFilters.address}
          </Paragraph>
        </div>
      </div>

      <div className="my-0 border-b border-gray-200" />
      <div className="flex flex-col gap-2">
        <BookingPriceDetailItem
          description={
            selectedFilters.immediatePickup === "1"
              ? "Enlèvement immédiat"
              : `Dépose de la benne`
          }
          icon={<Truck className="size-5 shrink-0" aria-hidden="true" />}
          subValue={`${formatPriceWithCurrency(displayedTransportDeliveryPrice, true)} ${isPro ? "HT" : "TTC"}`}
          valueClassName="font-normal"
          tooltip="hide"
          titleClassName="no-underline hover:underline"
        />
        {selectedFilters.immediatePickup === "1" ? (
          <BookingPriceDetailItem
            description={`${billedHours} heure inclue`}
            icon={
              <Truck
                className="size-5 shrink-0 -scale-x-100 transform"
                aria-hidden="true"
              />
            }
            subValue={`${formatPriceWithCurrency(displayedPricePerHour, true)} ${isPro ? "HT" : "TTC"}`}
            valueClassName="font-normal"
            tooltip="hide"
            titleClassName="no-underline hover:underline"
          />
        ) : (
          <BookingPriceDetailItem
            description={`Retrait de la benne`}
            icon={
              <Truck
                className="size-5 shrink-0 -scale-x-100 transform"
                aria-hidden="true"
              />
            }
            subValue={`${formatPriceWithCurrency(displayedTransportPickupPrice, true)} ${isPro ? "HT" : "TTC"}`}
            valueClassName="font-normal"
            tooltip="hide"
            titleClassName="no-underline hover:underline"
          />
        )}
        {selectedFilters.plans === "1" ? (
          <>
            <BookingPriceDetailItem
              description={`${equipmentWeight} tonne(s) comprise(s)`}
              icon={
                <ArrowTriangle aria-hidden="true" className="size-5 shrink-0" />
              }
              subValue={`${formatPriceWithCurrency(displayedTreatmentPrice, true)} ${isPro ? "HT" : "TTC"}`}
              valueClassName="font-normal"
              tooltip="hide"
              titleClassName="no-underline hover:underline"
            />

            <BookingPriceDetailItem
              description={`${rentDays} jours compris`}
              icon={<Time className="size-5 shrink-0" aria-hidden="true" />}
              subValue={`${formatPriceWithCurrency(displayedRentPrice, true)} ${isPro ? "HT" : "TTC"}`}
              valueClassName="font-normal"
              tooltip="hide"
              titleClassName="no-underline hover:underline"
            />
          </>
        ) : null}
      </div>
      <div className="my-0 border-b border-gray-200" />

      <div className="flex flex-col gap-4">
        <BookingPriceDetailItem
          description={`Total HT`}
          subValue={`${formatPriceWithCurrency(priceHT, true)}`}
          valueClassName="font-normal"
          titleClassName="font-normal no-underline hover:underline"
          hideIcon
          tooltip="hide"
        />
        <BookingPriceDetailItem
          description={`TVA 20%`}
          subValue={`${formatPriceWithCurrency(tvaFee, true)}`}
          valueClassName="font-normal"
          titleClassName="font-normal no-underline hover:underline"
          hideIcon
          tooltip="hide"
        />
        <BookingPriceDetailItem
          description={`Total TTC`}
          subValue={`${formatPriceWithCurrency(priceTTC, true)}`}
          titleClassName="font-normal no-underline hover:underline"
          hideIcon
          tooltip="hide"
        />
        <BookingPriceDetailItem
          description={`À régler maintenant`}
          subValue={`${formatPriceWithCurrency(priceTTC, true)}`}
          titleClassName="font-medium mt-2 text-base no-underline"
          valueClassName="text-xl hover:underline"
          hideIcon
          tooltip="hide"
        />
      </div>

      <div className="my-0 border-b border-gray-200" />
      <div className="flex flex-col gap-2">
        <BookingPriceDetailItem
          description={`À régler en fin de prestation`}
          subValue={``}
          titleClassName="font-medium mb-2 text-base no-underline"
          hideIcon
          tooltip="hide"
        />

        <BookingPriceDetailItem
          wrapperTitle="Prix de traitement :"
          wrapperDescription="Correspond au prix à la tonne qui sera facturé sur la facture finale après pesée de la benne en centre agrée. Le calcul se fera à la dizaine de Kg près. "
          description={`Prix de traitement ${waste.name.toLowerCase()} à la tonne`}
          icon={
            <ArrowTriangle aria-hidden="true" className="size-5 shrink-0" />
          }
          subValue={`+ ${formatPriceWithCurrency(displayedTreatmentPricePerTon, true)} ${isPro ? "HT" : "TTC"} / tonne`}
          tooltip="show"
        />
        <BookingPriceDetailItem
          wrapperTitle="Prix de location :"
          wrapperDescription="Correspond au prix à la journée d'immobilisation de la benne. Chaque journée entamée est due."
          description={`Location benne prix journalier`}
          icon={<Time aria-hidden="true" className="size-5 shrink-0" />}
          subValue={`+ ${formatPriceWithCurrency(displayedRentPricePerDay, true)} ${isPro ? "HT" : "TTC"} / Jour`}
          tooltip="show"
        />
        <BookingPriceDetailItem
          wrapperTitle="Prix de rotation :"
          wrapperDescription="Correspond au prix d'une rotation ou d'un transport supplémentaire entre le centre de traitement et votre site. Si vous souhaitez réserver des rotations supplémentaires : pensez à nous contacter 48h à l'avance pour organiser le transport. "
          description={`Rotation supplémentaire`}
          icon={<Refresh aria-hidden="true" className="size-5 shrink-0" />}
          subValue={`+ ${formatPriceWithCurrency(displayedTransportPrice, true)} ${isPro ? "HT" : "TTC"} / Rotation`}
          tooltip="show"
        />
      </div>
    </div>
  );
};
