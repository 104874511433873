import {
  RadioGroup,
  RadioGroupItem,
} from "components/shadcn-ui/radio-group.tsx";
import React from "react";
import { cn } from "utils/utils.ts";
import { Paragraph } from "./Paragraph";
import { Tag } from "./Tag";

type DoubleSelectOptionType = {
  id: string;
  name: string;
  value: string;
  disabled?: boolean;
  recommanded?: boolean;
  title: string;
  subtitle: string;
  mainLabel?: string;
  subLabel?: string;
};
export const DoubleSelect = ({
  firstOption,
  secondOption,
  defaultOption = "",
  name,
  disabled = false,
  onClick,
}: {
  name: string;
  defaultOption?: string;
  firstOption: DoubleSelectOptionType | null;
  secondOption: DoubleSelectOptionType;
  disabled?: boolean;
  onClick?: (value: string) => void;
}) => {
  const [selectedOption, setSelectedOption] = React.useState(defaultOption);
  React.useEffect(() => {
    setSelectedOption(defaultOption);
  }, [defaultOption]);
  return (
    <RadioGroup
      disabled={disabled}
      value={selectedOption}
      name={name}
      onValueChange={(newValue) => setSelectedOption(newValue)}
    >
      <div className="mt-1 flex flex-col">
        {firstOption ? (
          <DoubleSelectOption
            optionType="first"
            isSelected={selectedOption === firstOption.value}
            option={firstOption}
            onClick={onClick}
          />
        ) : null}
        <DoubleSelectOption
          optionType="second"
          isSelected={selectedOption === secondOption.value}
          option={secondOption}
          onClick={onClick}
          className={firstOption === null ? "rounded-t-[8px]" : ""}
        />
      </div>
    </RadioGroup>
  );
};

const DoubleSelectOption = ({
  option,
  isSelected,
  optionType,
  onClick,
  className,
}: {
  option: DoubleSelectOptionType;
  isSelected: boolean;
  optionType: "first" | "second";
  onClick?: (value: string) => void;
  className?: string;
}) => {
  return (
    <label
      aria-describedby={option.id}
      data-checked={isSelected ? "true" : "false"}
      className={cn(
        `relative flex cursor-pointer items-center gap-6 pb-4 border-2 bg-white px-5 ${option.recommanded ? "pt-2" : "pt-4"} ${isSelected && !option.disabled ? "border-primary" : option.disabled ? "cursor-not-allowed border-gray-300 opacity-85" : "hover:border-gray-600"}`,
        {
          "border-b-none peer/firstselect rounded-[8px] rounded-b-none hover:border-b-2 peer-data-[checked=true]:border-b-0":
            optionType === "first",
          "peer/secondselect rounded-[8px] rounded-t-none peer-hover/firstselect:border-t-0":
            optionType === "second",
        },
        className,
      )}
      htmlFor={option.id}
      key={option.id}
    >
      {/* <img
        alt=""
        src="https://goodcollect-strapi.s3.eu-west-3.amazonaws.com/construction_work_2698790_640_cf19705e87.jpg"
        width={640}
        height={427}
        className="rounded-md size-20"
      /> */}
      <div className="flex w-full flex-col gap-1.5">
        <div className="flex w-full items-center gap-4">
          <Paragraph size="lg" fontWeight={"bold"}>
            {option.title}
          </Paragraph>
          <div className="ml-auto flex items-center gap-4">
            {option.recommanded ? (
              <Tag variant="Light" type="Square">
                Recommandé
              </Tag>
            ) : null}
            <RadioGroupItem
              value={option.value}
              id={option.id}
              disabled={option.disabled}
              onClick={() => onClick?.(option.value)}
              className={cn("", {
                "border-gray-600 text-green-600 hover:border-green-600 hover:text-green-700 disabled:bg-gray-200 disabled:text-gray-500":
                  isSelected,
              })}
            />
          </div>
        </div>
        <Paragraph size="sm" fontWeight={"regular"}>
          {option.subtitle}
        </Paragraph>
        <div className="mt-2.5 flex flex-wrap items-center gap-1.5">
          <Paragraph size="sm" fontWeight={"bold"}>
            {option.mainLabel}
          </Paragraph>
          <Paragraph size="sm" fontWeight={"regular"} className="text-gray-500">
            {option.subLabel}
          </Paragraph>
        </div>
      </div>
    </label>
  );
};
