import Devtool from "components/Devtool.tsx";
import Footer from "components/Layout/Footer.tsx";
import { GeneralErrorBoundary } from "components/error-boundary.tsx";
import {
  Outlet,
  data,
  redirect,
  useLocation,
  useRouteLoaderData,
} from "react-router";

import { UserRoles } from "app/shared/schemas/user-roles.enum";
import { Navigation } from "components/Layout/Navigation.tsx";
import { PhoneWidget } from "components/Layout/Provider/PhoneWidget.tsx";
import { ContactUsSection } from "components/Sections/ContactUsSection";
import type { LoaderFunctionArgs } from "react-router";
import { generateCanonicalUrl } from "routes/redirects.server";
import {
  getBookingOffers,
  getTreatmentTypes,
} from "server/api/booking-pricing/services/provider-bookings-queries.server";
import { getCustomerPaymentMethods } from "server/api/customer/services/customer-billing.server";
import { formatParams } from "server/api/utils/bookings-utils.server";
import { getBookingPrice } from "server/public/transactional-page.server";
import { getUser } from "server/session.server";
import {
  getMarketplaceNewAccess,
  getMarketplaceOptionalOptions,
  getOfferItemDescription,
} from "server/utils.server";
import { useIsDevmode } from "utils/utils.ts";
import { v4 } from "uuid";

type LoaderResponse = {
  user: Awaited<ReturnType<typeof getUser>>;
  href: string;
  count: number;
  offerItemDescription: ReturnType<typeof getOfferItemDescription> | null;
  uniqueId: string; // <= This id is used for websocket purposes
  offer: Awaited<ReturnType<typeof getBookingPrice>> | null;
  customerPaymentMethods: Awaited<ReturnType<typeof getCustomerPaymentMethods>>;
  offersData: Awaited<ReturnType<typeof getBookingOffers>> | null;
} | null;

export const loader = async ({ request }: LoaderFunctionArgs) => {
  const currentUrlParams = new URL(request.url).searchParams;

  // check if url starts with /solutions/rechercher
  if (!request.url.includes("/solutions/rechercher")) {
    return data<LoaderResponse>(null);
  }
  let customerPaymentMethods: Awaited<
    ReturnType<typeof getCustomerPaymentMethods>
  > = {
    creditCards: [],
    sepaDebits: [],
  };

  const {
    shouldBeRedirectedToNearestAvailableStep,
    urlParams,
    searchUrl,
    options,
  } = getMarketplaceNewAccess({
    request,
  });

  if (shouldBeRedirectedToNearestAvailableStep) {
    return redirect(searchUrl);
  }

  if (options === null) {
    return redirect("/");
  }

  if (
    !currentUrlParams.has("step") &&
    currentUrlParams.toString() !== urlParams.toString()
  ) {
    return redirect("/");
  }
  const uniqueId = v4();

  // By default, server-side, we use the non-recurring prices and disable immediate pickups.
  options.isRecurring = "0";

  const user = await getUser({ request });

  const isAdmin =
    Boolean(user?.authOptions.isAdmin) || Boolean(options.startDate);
  const { startDate, endDate, haveOutdatedDatesBeenUpdated } =
    getMarketplaceOptionalOptions({
      isAdmin: isAdmin, // this argument means, if a startDate was provided (for example a date for tomorrow), we allow the customer to book for tomorrow.
      request,
      allowOutdated: false,
      pickNextAvailableDateIfOutdated: true,
    });

  if (options.step === "1") {
    return data<LoaderResponse>({
      user,
      offersData: null,
      uniqueId,
      href: generateCanonicalUrl({
        request,
      }),
      count: 0,
      offerItemDescription: null,
      offer: null,
      customerPaymentMethods,
    });
  }

  let count = 0;

  // Start of Step 2
  if (options.step === "2") {
    const treatmentTypeData = await getTreatmentTypes({
      wasteId: options.waste,
    });

    const offersData = await getBookingOffers({
      customerPriceOverrides: [],
      formattedParams: formatParams({
        paramsData: {
          ...options,
          startDate,
          endDate,
        },
      }),
      options: {
        allowOutdated: true,
        disableHistory: false,
        disallowWeekends: false,
        isAdmin: user?.authOptions?.isAdmin,
        pickNextAvailableDateIfOutdated: false,
      },
      treatmentTypeData,
    });
    count = offersData.allOffersCount;
    return data<LoaderResponse>({
      offersData,
      user,
      uniqueId,
      href: generateCanonicalUrl({ request }),
      count,
      offerItemDescription: null,
      offer: null,
      customerPaymentMethods,
    });
  }
  if (options.step === "3" || options.step === "4") {
    if (haveOutdatedDatesBeenUpdated && !isAdmin) {
      options.startDate = startDate;
      options.endDate = endDate;
      throw redirect(`/solutions/rechercher?${urlParams.toString()}`);
    }

    // Options 3 and 4 are getting the selected offer information
    const offer = await getBookingPrice({
      params: {
        ...options,
        startDate,
        endDate,
      },
      // biome-ignore lint/style/noNonNullAssertion: <explanation>
      providerId: options.providerId!,
      isAdmin: false,
    });

    if (user && user.role === UserRoles.CUSTOMER) {
      customerPaymentMethods = await getCustomerPaymentMethods({
        contextPayload: {
          userId: user?.id,
          role: user?.role,
        },
      });
    }

    return data<LoaderResponse>({
      offersData: null,
      user,
      uniqueId,
      href: generateCanonicalUrl({ request }),
      count,
      offerItemDescription: getOfferItemDescription({
        acceptedWastes: offer.offer.waste.acceptedWaste,
        dangerousWaste: offer.offer.waste.dangerousWaste,
        forbiddenWastes: offer.offer.waste.forbiddenWaste,
      }),
      offer,
      customerPaymentMethods,
    });
  }

  throw new Error("Invalid step");
};

const MainLayout = () => {
  const isDev = useIsDevmode();
  const location = useLocation();
  const disallowedPhoneUrls = [
    "/contact",
    "/dechetterie",
    "/rgpd",
    "/404",
    "/cgv",
    "/legal",
  ];
  const hidePhoneCta = disallowedPhoneUrls.some((disallowedUrl) =>
    location.pathname.includes(disallowedUrl),
  );

  const disallowedContactUsUrls = [
    "/contact",
    "/login",
    "/register-provider",
    "/solutions",
  ];
  const hideContactUsSection = disallowedContactUsUrls.some((disallowedUrl) =>
    location.pathname.includes(disallowedUrl),
  );

  const locationKey = useLocation().key;
  return (
    <div className="relative">
      {isDev ? <Devtool /> : null}

      <Navigation key={locationKey} />
      {hidePhoneCta ? null : <PhoneWidget />}
      <Outlet />
      {hideContactUsSection ? null : <ContactUsSection />}
      <Footer />
    </div>
  );
};

export const usePublicLayout = () => {
  const data = useRouteLoaderData<LoaderResponse>("routes/_public+/_layout");
  if (!data) return null;
  return data;
};
export default MainLayout;

export function ErrorBoundary() {
  const locationKey = useLocation().key;
  return (
    <div className="relative">
      <Navigation key={locationKey} />
      <GeneralErrorBoundary />
      <Footer />
    </div>
  );
}
