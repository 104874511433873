import createCache, { type EmotionCache } from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
import { OptimizedImage } from "components/OptimizedImage.tsx";
import { Label } from "components/UI/Label.tsx";
import { paragraphVariants } from "components/UI/Paragraph.tsx";
import { User } from "iconoir-react";
import { XIcon } from "lucide-react";
import React, { useEffect, useId, useMemo, useState } from "react";
import Select, {
  type MenuProps,
  type OptionProps,
  type PlaceholderProps,
  type SingleValueProps,
  components,
} from "react-select";
import CreatableSelect from "react-select/creatable";
import { cn } from "utils/utils.ts";
import { ErrorList, type ListOfErrors } from "../forms.tsx";

export type ComboboxOption = {
  id: string;
  label: string;
  imageUrl?: string;
  showImage?: boolean;
  disabled?: boolean;
};

const PlaceholderComponent = ({
  children,
  icon = <User className="size-6 shrink-0" aria-hidden="true" />,
  ...props
}: PlaceholderProps<ComboboxOption> & { icon?: React.ReactNode }) => {
  return (
    <components.Placeholder
      {...props}
      className={cn(props.className, "inline-flex items-center gap-2")}
    >
      {icon}
      <span>{children}</span>
    </components.Placeholder>
  );
};

const SingleValueComponent = ({
  children,
  icon = <User className="size-6 shrink-0" aria-hidden="true" />,
  showImage,
  imageUrl,
  ...props
}: SingleValueProps & {
  icon?: React.ReactNode;
  showImage?: boolean;
  imageUrl?: string;
}) => {
  return (
    <components.SingleValue
      {...props}
      className={cn(props.className, "inline-flex items-center gap-2")}
    >
      {icon}
      <span>{children}</span>
    </components.SingleValue>
  );
};

const MenuComponent = ({
  children,
  headerOption,
  ...props
}: MenuProps<ComboboxOption> & { headerOption?: React.ReactNode }) => {
  return (
    <components.Menu
      {...props}
      className={cn(
        props.className,
        "overflow-hidden rounded-[8px] border border-gray-300",
      )}
    >
      {headerOption}
      {children}
    </components.Menu>
  );
};

const OptionComponent = ({
  showImage,
  imageUrl,
  ...props
}: OptionProps & {
  showImage?: boolean;
  imageUrl?: string;
}) => {
  return (
    <components.Option
      {...props}
      className={cn(props.className, "flex items-center gap-2")}
    >
      {showImage && imageUrl ? (
        <OptimizedImage
          source={imageUrl}
          maxHeight={32}
          containerClassName="w-fit mx-0"
          alt={props.label}
          className=""
        />
      ) : null}
      <span>{props.label}</span>
    </components.Option>
  );
};

// This ensures that Emotion's styles are inserted before Tailwind's styles so that Tailwind classes have precedence over Emotion
const EmotionCacheProvider = ({ children }: { children: React.ReactNode }) => {
  const [cache, setCache] = useState<EmotionCache | null>(null);

  useEffect(() => {
    const newCache = createCache({
      key: "with-tailwind",
      // biome-ignore lint/style/noNonNullAssertion: <explanation>
      insertionPoint: document.querySelector("title")!,
    });
    setCache(newCache);
  }, []);

  if (!cache) return <>{children}</>;

  return <CacheProvider value={cache}>{children}</CacheProvider>;
};

export type ComboboxCommonProps = {
  headerOption?: React.ReactNode;
  options: ComboboxOption[];
  placeholder?: string;
  disabled?: boolean;
  label?: string;
  errors?: ListOfErrors;
  className?: string;
  theme?: "lead" | "classic";
  icon?: React.ReactNode;
  onClear?: () => void;
  allowCustom?: boolean; // new prop to allow creating custom values
  limit?: number;
};

type SingleComboboxProps = ComboboxCommonProps & {
  selectedOptionId: string | number | null;
  onChange: (value: string | null) => void;
  type: "single";
};

type MultipleComboboxProps = ComboboxCommonProps & {
  selectedOptionIds: string[];
  onChange: (value: string[]) => void;
  type: "multiple";
};
export const GeneralCombobox = (
  props: SingleComboboxProps | MultipleComboboxProps,
) => {
  const {
    onChange,
    placeholder = "",
    disabled = false,
    label,
    errors = null,
    theme = "classic",
    className,
    headerOption = null,
    icon,
    type,
    options,
    allowCustom = false,
    limit = 30,
  } = props;

  const [query, setQuery] = React.useState("");

  const filteredOptions: ComboboxOption[] = useMemo(() => {
    // If using the standard approach, do basic filtering
    return options
      .filter((option) => {
        const labelValue = option.label ?? "";
        return labelValue.toLowerCase().includes(query.toLowerCase());
      })
      .slice(0, limit)
      .map((option) => ({
        id: option.id ?? "",
        value: option.id ?? "",
        label: option.label ?? "",
        isDisabled: option.disabled,
        showImage: option.showImage,
        imageUrl: option.imageUrl,
      }));
  }, [options, query]);

  const selectedOption = React.useMemo(() => {
    if (type === "multiple") return [];
    const foundOption = filteredOptions.find(
      (o) => o.id?.toString() === props.selectedOptionId?.toString(),
    );
    if (foundOption) {
      return {
        id: foundOption.id,
        name: foundOption.label,
        // value: foundOption.value,
        label: foundOption.label,
        isDisabled: foundOption.disabled,
      };
    }
    // If we didn't find an option and we have a custom text, let's just show that as "label = param"
    if (props.selectedOptionId && !foundOption && allowCustom) {
      return {
        value: props.selectedOptionId.toString(),
        label: props.selectedOptionId.toString(),
        id: props.selectedOptionId.toString(),
      };
    }
    return null;
  }, [filteredOptions, props, type, allowCustom]);

  const selectedOptions = React.useMemo(() => {
    if (type === "single") return null;
    return props.selectedOptionIds.map((id) => {
      const foundOption = filteredOptions.find(
        (o) => o.id?.toString() === id.toString(),
      );
      if (foundOption) {
        return {
          id: foundOption.id,
          label: foundOption.label,
          isDisabled: foundOption.disabled,
        };
      }
      if (allowCustom) {
        return {
          value: id.toString(),
          label: id.toString(),
        };
      }
      return null;
    });
  }, [filteredOptions, props, type, allowCustom]);

  const id = useId();

  return (
    <EmotionCacheProvider>
      <div className={cn("w-full", className)}>
        <div
          className={cn("relative flex w-full flex-col gap-2", {
            "bg-white": theme === "lead",
          })}
        >
          {label ? (
            <Label
              htmlFor={id}
              as="label"
              className={cn(
                paragraphVariants({
                  variant: "secondary",
                  size: "md",
                }),
                "text-start font-medium",
              )}
              aria-label={label}
            >
              {label}
            </Label>
          ) : null}

          <div className="w-full">
            {allowCustom ? (
              <CreatableSelect
                id={id}
                formatCreateLabel={(input) => `Rechercher "${input}"`}
                inputValue={query}
                onInputChange={(value) => setQuery(value)}
                placeholder={placeholder}
                value={type === "multiple" ? selectedOptions : selectedOption}
                isDisabled={disabled}
                isSearchable
                isMulti={type === "multiple"}
                onChange={(optionOrValue) => {
                  if (type === "single") {
                    if (!optionOrValue) {
                      onChange(null);
                      return;
                    }
                    // @ts-ignore
                    onChange(optionOrValue.value || null);
                  } else {
                    if (!optionOrValue) {
                      onChange([]);
                      return;
                    }
                    // @ts-ignore
                    onChange(optionOrValue.map((o) => o.value));
                  }
                }}
                styles={{
                  input: (base) => ({
                    ...base,
                    "input:focus": {
                      boxShadow: "none",
                    },
                  }),
                  indicatorSeparator: () => ({
                    visibility: "hidden",
                  }),
                }}
                classNames={{
                  input: ({ className }) => cn(className, {}),
                  placeholder: ({ className }) =>
                    cn(className, "text-base", {
                      "text-gray-300": disabled,
                      "text-dark-iron": !disabled,
                    }),
                  control: ({ className, isFocused }) =>
                    cn(
                      className,
                      "ring-0 outline-0 h-full rounded-[8px] border active:border-2 transition-colors duration-150 py-[5px] px-1",
                      {
                        "border-gray-500 hover:border-green-400 active:border-primary":
                          isFocused,
                        "border-gray-200 hover:border-green-400 active:border-primary":
                          !isFocused,
                        "border-rouge hover:border-rouge active:border-rouge":
                          Boolean(errors),
                        "bg-gray-100 border-gray-300": disabled,
                      },
                    ),
                  menuList: ({ className }) =>
                    cn(className, "py-0 border-none"),
                  menu: ({ className }) =>
                    cn(
                      className,
                      "rounded-[8px] border-gray-300 border overflow-hidden",
                    ),
                  option: ({ className, isDisabled }) =>
                    cn(
                      className,
                      "font-normal rounded-none text-base p-3 bg-transparent hover:bg-transparent border-b border-b-gray-300",
                      {
                        "text-gray-300": isDisabled,
                        "text-gray-500 hover:text-green-500 active:text-gray-800 active:bg-gray-100":
                          !isDisabled,
                      },
                    ),
                }}
                className={cn("min-w-[240px]", className)}
                options={filteredOptions}
                components={{
                  ClearIndicator: () => {
                    if (!selectedOption && !selectedOptions) {
                      return null;
                    }
                    return (
                      <button
                        type="button"
                        onClick={() => {
                          props.onClear?.();
                        }}
                      >
                        <XIcon className="shrink-0 size-5 text-slate-500 hover:text-slate-700" />
                      </button>
                    );
                  },
                  Placeholder: (p) => PlaceholderComponent({ ...p, icon }),
                  SingleValue: (p) =>
                    SingleValueComponent({
                      ...p,
                      icon,
                      showImage: p.data?.showImage,
                      imageUrl: p.data?.imageUrl,
                    }),
                  Option: (p) =>
                    OptionComponent({
                      ...p,
                      showImage: p.data?.showImage,
                      imageUrl: p.data?.imageUrl,
                    }),
                  Menu: (p) => MenuComponent({ ...p, headerOption }),
                }}
                isClearable
                noOptionsMessage={() => "Aucun résultat trouvé."}
              />
            ) : (
              <Select
                id={id}
                inputValue={query}
                onInputChange={(value) => setQuery(value)}
                placeholder={placeholder}
                value={type === "multiple" ? selectedOptions : selectedOption}
                isDisabled={disabled}
                isSearchable
                isMulti={type === "multiple"}
                onChange={(option) => {
                  if (type === "single") {
                    if (option) {
                      // @ts-ignore
                      onChange(option?.value);
                    } else {
                      onChange(null);
                    }
                  }
                  if (type === "multiple") {
                    if (option) {
                      // @ts-ignore
                      onChange(option.map((o) => o.value));
                    } else {
                      onChange([]);
                    }
                  }
                }}
                styles={{
                  input: (base) => ({
                    ...base,
                    "input:focus": {
                      boxShadow: "none",
                    },
                  }),
                  indicatorSeparator: () => ({
                    visibility: "hidden",
                  }),
                }}
                classNames={{
                  input: ({ className }) => cn(className, {}),
                  placeholder: ({ className }) =>
                    cn(className, "text-base", {
                      "text-gray-300": disabled,
                      "text-dark-iron": !disabled,
                    }),
                  control: ({ className, isFocused }) =>
                    cn(
                      className,
                      "ring-0 outline-0 h-full rounded-[8px] border active:border-2 transition-colors duration-150 py-[5px] px-1",
                      {
                        "border-gray-500 hover:border-green-400 active:border-primary":
                          isFocused,
                        "border-gray-200 hover:border-green-400 active:border-primary":
                          !isFocused,
                        "border-rouge hover:border-rouge active:border-rouge":
                          Boolean(errors),
                        "bg-gray-100 border-gray-300": disabled,
                      },
                    ),
                  menuList: ({ className }) =>
                    cn(className, "py-0 border-none"),
                  menu: ({ className }) =>
                    cn(
                      className,
                      "rounded-[8px] border-gray-300 border overflow-hidden",
                    ),
                  option: ({ className, isDisabled }) =>
                    cn(
                      className,
                      "font-normal rounded-none text-base p-3 bg-transparent hover:bg-transparent border-b border-b-gray-300",
                      {
                        "text-gray-300": isDisabled,
                        "text-gray-500 hover:text-green-500 active:text-gray-800 active:bg-gray-100":
                          !isDisabled,
                      },
                    ),
                }}
                className={cn("min-w-[240px]", className)}
                options={filteredOptions}
                components={{
                  ClearIndicator: () => {
                    if (!selectedOption && !selectedOptions) {
                      return null;
                    }
                    return (
                      <button
                        type="button"
                        onClick={() => {
                          props.onClear?.();
                        }}
                      >
                        <XIcon className="shrink-0 size-5 text-slate-500 hover:text-slate-700" />
                      </button>
                    );
                  },
                  Placeholder: (p) => PlaceholderComponent({ ...p, icon }),
                  SingleValue: (p) =>
                    SingleValueComponent({
                      ...p,
                      icon,
                      showImage: p.data?.showImage,
                      imageUrl: p.data?.imageUrl,
                    }),
                  Option: (p) =>
                    OptionComponent({
                      ...p,
                      showImage: p.data?.showImage,
                      imageUrl: p.data?.imageUrl,
                    }),
                  Menu: (p) => MenuComponent({ ...p, headerOption }),
                }}
                isClearable
                noOptionsMessage={() => "Aucun résultat trouvé."}
              />
            )}
          </div>
          {errors ? <ErrorList errors={errors} /> : null}
        </div>
      </div>
    </EmotionCacheProvider>
  );
};
